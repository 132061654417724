import { useState } from 'react';
import { resetPassword } from '../../services/resetPasswordService';
import { TextField, Button, Typography, Box } from '@mui/material';
import './ResetPasswordForm.css'; // Assuming the CSS for background image
import LoadingButton from '../../components/LoadingButton';
import LoadingForm from '../../components/LoadingForm';

const ResetPasswordForm = () => {
  const [password, setPassword] = useState('');
  const [token] = useState(new URLSearchParams(window.location.search).get('token'));
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await resetPassword(token, password);
      setMessage(res.msg);
    } catch (err) {
      setMessage(err.message || 'Server error');
    }
  };

  return (
    <div className="container">
      <Box className="form-content">
        <Typography variant="h4" gutterBottom>
          Reset Password
        </Typography>
        <LoadingForm onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            label="New Password"
            type="password"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            autoComplete="off"
            size="small" // Compact field size
          />
          <LoadingButton fullWidth variant="contained" color="primary" type="submit" sx={{ mt: 2 }}>
            Submit
          </LoadingButton>

          {message && (
            <Typography color="error" sx={{ mt: 2 }}>
              {message}
            </Typography>
          )}
        </LoadingForm>
      </Box>
    </div>
  );
};

export default ResetPasswordForm;