import RegisterForm from '../../components/Register/RegisterForm';

const RegisterPage = () => {
  return (
    <div>
      <RegisterForm />
    </div>
  );
};

export default RegisterPage;