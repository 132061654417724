import ResetPasswordForm from '../../components/ResetPassword/ResetPasswordForm';

const ResetPasswordPage = () => {
  return (
    <div>
      <ResetPasswordForm />
    </div>
  );
};

export default ResetPasswordPage;